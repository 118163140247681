<template>
    <div v-if="banner" class="flex items-center gap-x-6 bg-secondary px-6 py-2.5 sm:px-3.5 sm:before:flex-1 animate-pulse">
        <p class="text-sm leading-6 text-white">
            <a href="#">
                <strong class="font-semibold">Veletrh již začal!</strong><svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1" /></svg>Připojte se k nám ve ČTVRTEK a PÁTEK v 8:30 - 18:00 a v SOBOTU v 8:30 - 15:00!
            </a>
        </p>
        <div class="flex flex-1 justify-end">
            <button @click="banner = false;" type="button" class="-m-3 p-3 focus-visible:outline-offset-[-4px]">
                <span class="sr-only">Dismiss</span>
                <XMarkIcon class="h-5 w-5 text-white" aria-hidden="true" />
            </button>
        </div>
    </div>
    <!-- Header bar -->
    <div class="bg-white">
        <div class="py-2 md:py-4 px-4 sm:px-8 lg:mx-auto lg:max-w-5xl flex items-center space-between justify-between">
            <!-- Logo -->
            <div class="">
                <router-link to="/">
                    <img class="h-8" src="/logo.png" alt="">
                </router-link>
            </div>
            <!-- Hamburger -->
            <button @click="open = !open" class="p-4 border-[2px] border-primary/20 rounded md:hidden">
                <svg height="12" viewBox="0 0 14 12" class="fill-primary">
                    <path d="M13,15a1,1,0,0,1,1-1H26a1,1,0,0,1,0,2H14a1,1,0,0,1-1-1Zm0,5a1,1,0,0,1,1-1h6a1,1,0,0,1,0,2H14a1,1,0,0,1-1-1Zm0,5a1,1,0,0,1,1-1H26a1,1,0,0,1,0,2H14a1,1,0,0,1-1-1Z" transform="translate(-13 -14)" fill="current" fill-rule="evenodd"/>
                </svg>
            </button>
            <!-- Standard menu -->
            <div class="hidden md:block">
                <div class="flex items-center">
                    <div class="ml-10 space-x-8">
                        <a v-for="link in navigation.filter(link => link.public)" :key="link.name" :href="link.href" class="text-primary-text font-medium hover:text-primary">{{ link.name }}</a>
                    </div>
                </div>
            </div>
        </div>

        <TransitionRoot as="template" :show="open">
            <Dialog as="div" class="relative z-10" @close="open = false">
            <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-secondary-light bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
                        <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                            <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
                            <div class="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                                <button type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" @click="open = false">
                                <span class="sr-only">Zavřít menu</span>
                                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            </TransitionChild>
                            <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                            <div class="px-4 sm:px-6">
                                <DialogTitle class="text-lg font-medium text-gray-900">Hlavní menu</DialogTitle>
                            </div>
                            <div class="relative mt-6 flex-1 px-4 sm:px-6 space-y-4 divide-y divide-solid">
                                <!-- Mobilní menu -->
                                <router-link v-for="link in navigation.filter(link => link.public)" :key="link.name" :to="link.href" class="text-primary-text font-medium hover:text-primary pt-4 block text-2xl">{{ link.name }}</router-link>
                            </div>
                            </div>
                        </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
            </Dialog>
        </TransitionRoot>
    </div>
</template>

<script>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'

export default {
    name: 'HeaderBar',
    data() {
        return {
            banner: true,
            open: false,
            navigation: [
                { name: 'Pro rodiče', href: '/forParents', public: false },
                { name: 'Pro návštěvníky', href: '/schools', public: true },
                { name: 'Pro vystavovatele', href: '/exhibitors', public: true },
                { name: 'O veletrhu', href: '/about', public: true },
                { name: 'українська', href: '/ukraine', public: false },
                { name: 'Ke stažení', href: '/download', public: true },
                { name: 'Přijímací řízení', href: '/admissions', public: true },
                { name: 'Kontakt', href: '/contact', public: true },

                { name: 'Vítej', href: '/', public: false },
                { name: 'Rodič', href: '/parents', public: false },
                { name: 'Žák', href: '/students', public: false }
            ]
        }
    },
    components: {
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        XMarkIcon
    }
}
</script>
