<template>
  <!-- Hero -->
  <div style="background-position: right top, left;" v-if="$route.name == 'home'" class="bg-no-repeat xl:bg-[url(/hero_right.png),_url(/hero_left.png)] bg-secondary-light md:bg-contain md:bg-no-repeat py-28 pb-4">
    <div class="md:max-w-xl lg:mx-auto lg:max-w-[71.5rem]">
      <SearchBar @s-change="search" :wait="true"></SearchBar>
    </div>
  </div>
  <!-- Router -->
  <router-view></router-view>
</template>

<script>
import HeaderBar from './components/HeaderBar.vue';
import SearchBar from './components/SearchBar.vue';
import FooterBar from './components/FooterBar.vue';
import ExternalText from './components/ExternalText.vue';

import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/24/outline'
import Cookies from 'js-cookie';

export default {
  name: 'App',
  data() {
    return {
      phrase: '',
      searchObject: null,
      modal: false
    }
  },
  created() {
    const modalCookie = Cookies.get('modal');

    if (modalCookie == undefined || modalCookie == false || modalCookie == 'false') {
      this.modal = true;
    }
  },
  components: {
    HeaderBar,
    SearchBar,
    FooterBar,
    ExternalText,
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, CheckIcon
  },
  methods: {
    handleChange(e) {
      this.searchObject = e;
    },
    search(e) {
      // named route with params to let the router build the url
      this.$router.push({name: 'search', query: {s: JSON.stringify(e)}});
    },
    closeModal() {
      this.modal = false;
      Cookies.set('modal', true, {expires: 7}); // Hodnota uložena na 7 dní
    }
  }
}
</script>
<style>
/* Hide scrollbar */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
