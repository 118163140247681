<template>
    <!-- Zameření -->
    <div class="p-4 pt-0 sm:pt-4 hidden">
        <div class="lg:mx-auto lg:max-w-5xl">
            <div class="bg-white">
                <div class="mx-auto max-w-7xl py-8 px-4 sm:px-12 lg:px-8">
                    <div class="space-y-6">
                        <div class="space-y-2 sm:space-y-1 md:max-w-xl lg:max-w-3xl xl:max-w-none">
                            <h2 class="text-xl sm:text-2xl text-primary-text font-bold tracking-tight sm:text-3xl">Zaměření škol</h2>
                            <p class="text-md sm:text-lg text-gray-500">Další zaměření připravujeme</p>
                        </div>
                        <ul role="list" class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
                            <li v-for="item in items" :key="item.id">
                                <div class="hover:cursor-pointer hover:opacity-[.8] space-y-4" @click="selectedItem = item; open = true;">
                                    <div class="border rounded-lg">
                                        <img class="rounded-lg object-cover shadow-lg h-[170px] w-full" :src="`https://www.200solutions.com/sp/zam_${item.id}.jpg`" alt="Náhledový obrázek oboru" />
                                    </div>
                                    <div class="space-y-2">
                                        <div class="space-y-1 text-lg font-medium leading-6 text-left">
                                            <h3>{{ item.title }}</h3>
                                            <p class="text-primary text-sm text-left">{{ item.content }}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Nová videa -->
    <div class="lg:mx-auto lg:max-w-5xl px-8 hidden">
        <div class="bg-secondary-light bg-[url(/illustrative_background_addon.png)] bg-right bg-contain bg-no-repeat rounded-2xl mb-8 h-full mt-6 flex flex-col sm:flex-row flex-col-reverse">
            <!-- Foto -->
            <div class="w-full h-full">
                <img src="illustrative_image.png" class="h-full w-full -mt-[32px] -ml-[16px]" alt="Sexy Kačka">
            </div>
            <!-- Text -->
            <div class="w-full p-8 flex items-center">
                <div class="sm:space-y-1 md:max-w-xl lg:max-w-3xl xl:max-w-none">
                    <h2 class="text-xl sm:text-3xl font-bold tracking-tight text-primary">Průvodce přijímačkami</h2>
                    <p class="text-md sm:text-lg text-secondary-text pb-6">Připrav se s námi na přijímačky.</p>
                    <router-link to="/pruvodce">
                        <button type="button" class="inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 mr-2 text-white">
                                <path fill-rule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clip-rule="evenodd" />
                            </svg>
                            Pustit průvodce
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>

    <!-- Průvodce -->
    <div class="lg:mx-auto lg:max-w-5xl px-8 hidden">
        <div class="bg-secondary-light bg-[url(/pattern_mobile.png)] bg-right bg-contain bg-no-repeat rounded-2xl mb-16 h-full flex sm:flex-row flex-col-reverse">
            <!-- Text -->
            <div class="w-full p-8 flex items-center">
                <div class="space-y-2 sm:space-y-1 md:max-w-xl lg:max-w-3xl xl:max-w-none">
                    <h2 class="text-xl sm:text-2xlfont-bold tracking-tight font-bold text-primary">Průvodce výběrem SŠ</h2>
                    <p class="text-md sm:text-lg text-secondary-text">Starší studenti tě provedou celým výběrem střední školy.</p>
                    <router-link to="/tutornew">
                        <button type="button" class="inline-flex items-center mt-4 rounded-md border-2 border-primary px-4 py-2 text-base font-medium text-primary shadow-sm focus:outline-none focus:ring-2 hover:bg-primary-light focus:ring-primary focus:ring-offset-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 mr-2 text-primary">
                                <path fill-rule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clip-rule="evenodd" />
                            </svg>

                            Pustit průvodce
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>

    <!-- Logos -->
    <div class="bg-white sm:mt-0 hidden">
        <div class="lg:mx-auto lg:max-w-5xl">
            <div class="mx-auto max-w-7xl pb-16 px-8">
                <h2 class="text-xl sm:text-2xl text-primary-text font-bold tracking-tight">Organizátoři veletrhu</h2>
                <p class="text-lg text-gray-500 py-1">Veletrh organizuje Magistrát hl. m. Prahy a Mediální dům Preslova</p>
                <div class="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
                    <div class="col-span-1 flex justify-center bg-gray-200/30 py-8 px-8 rounded-md">
                        <a href="https://www.prahaskolska.eu/mhmp"><img class="max-h-12" src="/mhmp_logo.svg" alt="Magistrát hl. města Prahy" /></a>
                    </div>
                    <!--
                    <div class="col-span-1 flex justify-center bg-gray-200/30 py-8 px-8 rounded-md">
                        <a href="https://www.200solutions.com"><img class="max-h-12" src="/200_logo.svg" alt="200solutions s.r.o." /></a>
                    </div>
                    <div class="col-span-1 flex justify-center bg-gray-200/30 py-8 px-8 rounded-md">
                        <a href="https://verticalproduction.cz"><img class="max-h-12" src="/vertical_logo.svg" alt="Vertical production s.r.o." /></a>
                    </div> -->
                    <div class="col-span-1 flex justify-center bg-gray-200/30 py-8 px-8 rounded-md">
                        <a href="https://prahaskolska.eu"><img class="max-h-12" src="/PrahaSkolska_logo.png" alt="Vertical production s.r.o." /></a>
                    </div>
                    <div class="col-span-1 flex justify-center bg-gray-200/30 py-8 px-8 rounded-md">
                        <img class="max-h-12 opacity-50" src="/mdp.svg" alt="Mediální dům Preslova" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Zaměření -->
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="open = false">
            <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-secondary bg-opacity-10 transition-opacity" />
            </TransitionChild>
            <div class="fixed inset-0" />
            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-2xl">
                                    <div class="px-4 py-6 sm:px-8">
                                        <div class="flex items-start justify-between">
                                        <h2 id="slide-over-heading" class="text-2xl font-medium text-gray-900">{{ selectedItem.title }}</h2>
                                        <div class="ml-3 flex h-7 items-center">
                                            <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-primary" @click="open = false">
                                                <span class="sr-only">Close panel</span>
                                                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                    <!-- Main -->
                                    <div>
                                        <div class="pb-1 sm:pb-6">
                                            <div>
                                                <div class="relative h-40 sm:h-56">
                                                    <!-- Video -->     
                                                    <div class="mx-auto w-full p-8 pt-0 pb-0 pr-32">
                                                        <video
                                                            v-if="selectedItem.id < 9"
                                                            id="my-video"
                                                            class="video-js w-full h-full rounded-lg overflow-hidden shadow-xl"
                                                            controls
                                                            preload="auto"
                                                            :data-setup="JSON.stringify({ fill: true })"
                                                        >
                                                            <source :src="`https://www.200solutions.com/sp/zam_${selectedItem.id}.mp4`" type="video/mp4" /> <!--  -->
                                                            <p class="vjs-no-js">
                                                                Zdá se, že Váš telefon nepodporuje přehrávání videí pomocí HTML5.
                                                            </p>
                                                        </video>
                                                    </div>
                                                    <!-- Content -->
                                                    <div class="p-8 pb-0 text-justify"  v-if="selectedItem.id < 9">
                                                        <p class="font-bold border-b pb-2 mb-4">Článek o zaměření</p>

                                                        <p :style="{ height: hidden ? '200px' : 'auto' }" :class="{ 'relative after:bottom-0 after:left-0 after:w-full after:h-3/4 after:absolute after:z-10 after:bg-gradient-to-t after:to-transparent after:from-white': hidden }" style="text-align: justify; color: #707070; overflow: hidden; text-overflow: ellipsis;">
                                                            <ExternalText :url="`https://www.200solutions.com/sp/zam_${selectedItem.id}.txt`"></ExternalText>                    
                                                        </p>
                                                        <button @click="hidden = !hidden" small class="display-flex" style="width: 100%; padding: 16px 10px; justify-content: center; color: var(--f7-theme-color);">
                                                            <span class="text-primary hover:underline">{{ hidden ? 'Číst více' : 'Skrýt' }}</span>
                                                        </button>
                                                    </div>
                                                    <!-- Doporučené školy -->
                                                    <div class="p-8">
                                                        <p class="font-bold border-b pb-2 mb-4">Doporučené školy</p>

                                                        <button @click="getRelatedSchools(selectedItem.id)" type="button" class="w-full inline-flex items-center rounded-md border border-transparent bg-primary px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 text-center justify-center">Zobrazit školy</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                    
                                        </div>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import ExternalText from '../components/ExternalText.vue';
import specializationsSorted from '../assets/specializationsSorted.json';

import {
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid'

export default {
    name: 'HomeView',
    data() {
        return {
            items: [
                    {
                        id: 1,
                        title: 'Průmyslové školy IT',
                        content: "6 oborů"
                    },
                    {
                        id: 2,
                        title: 'Průmyslové školy strojní',
                        content: "28 oborů"
                    },
                    {
                        id: 3,
                        title: 'Zdravotnictví a pečovatelství',
                        content: "14 oborů"
                    },
                    {
                        id: 4,
                        title: 'Konzervatoře',
                        content: "5 oborů"
                    },
                    {
                        id: 5,
                        title: 'Gymnázia',
                        content: "3 obory"
                    },
                    {
                        id: 6,
                        title: 'Odborná učiliště',
                        content: "59 oborů"
                    },
                    {
                        id: 7,
                        title: 'Cestovní ruch',
                        content: "8 oborů"
                    },
                    {
                        id: 8,
                        title: 'Obchodní a ekonomické školy',
                        content: "4 obory"
                    },
                    {
                        id: 9,
                        title: 'Zemědělské školy',
                        content: "28 oborů"
                    },
                    /*{
                        id: 10,
                        title: 'Ekologie',
                        content: "3 obory"
                    },
                    {
                        id: 11,
                        title: 'Hornictví a slévařství',
                        content: "3 obory"
                    }, */
                    {
                        id: 12,
                        title: 'Průmyslové školy elektrotechnické',
                        content: "3 obory"
                    },
                    {
                        id: 13,
                        title: 'Potravinářství a chemie',
                        content: "3 obory"
                    },
                    {
                        id: 14,
                        title: 'Textilnictví',
                        content: "3 obory"
                    },
                    {
                        id: 15,
                        title: 'Ostatní obory',
                        content: "3 obory"
                    },
                    {
                        id: 16,
                        title: 'Grafika a fotografie',
                        content: "3 obory"
                    },
                    {
                        id: 17,
                        title: 'Stavebnictví',
                        content: "3 obory"
                    },
                    {
                        id: 18,
                        title: 'Doprava',
                        content: "3 obory"
                    },
                    /*{
                        id: 20,
                        title: 'Veterinářství',
                        content: "3 obory"
                    }, */
                    {
                        id: 21,
                        title: 'Policejní obory',
                        content: "3 obory"
                    },
                    {
                        id: 22,
                        title: 'Pedagogika',
                        content: "3 obory"
                    },
                    {
                        id: 23,
                        title: 'Odborná lycea',
                        content: '4 obory'
                    },
                    {
                        id: 24,
                        title: 'Umělecké obory',
                        content: '4 obory'
                    }
            ],
            selectedItem: null,
            open: false,
            hidden: true
        }
    },
    methods: {
        getRelatedSchools(customId) {
            // let specializations = specializationsSorted.filter(item => item.custom == customId).map(item => item.specialization);
            // Get codes of schools
            let specializations = specializationsSorted.filter(
                item => {
                    return item.custom.includes(parseInt(customId));
                }
            ).map(item => item.specialization);

            // named route with params to let the router build the url
            this.$router.push({ name: 'search', query: { s: JSON.stringify({
                phrase: '',
                area: [],
                membership: [],
                specializations: specializations
            }) }});
        }   
    },
    components: {
        ExternalText,

        Dialog,
        DialogPanel,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TransitionChild,
        TransitionRoot,
        XMarkIcon,
        EllipsisVerticalIcon
    }
}
</script>
